// Styles for Calendar
// -----------------------------------------------------------------------------
#ui-datepicker-div {
  font-size: 80% !important;
}

.ui-datepicker .ui-datepicker-prev span {
  display: none !important;
}

.ui-datepicker .ui-datepicker-next span {
  display: none !important;
}

.region-pre-content {
  .block {
    overflow: hidden;

    .block__title {
      display: none;
    }
  }
}

.date-nav {
  padding: 0;

  div.date-prev,
  div.date-heading,
  div.date-next {
    display: inline-block;
    margin: 40px 20px 0;
    vertical-align: middle;

    @include xs {
      margin-left: 0;
      margin-right: 0;
      padding: 0 15px;
    }
  }

  div.date-heading {
    h1 {
      font-size: 60px;
      line-height: 60px;
      margin: 0;
      text-transform: uppercase;

      @include xs {
        font-size: 34px;
      }

      @include xxs {
        font-size: 26px;
        text-transform: none;
      }

      a {
        color: $blue;

        &:focus,
        &:hover {
          color: $blue;
          text-decoration: none;
          cursor: default;
        }
      }
    }
  }

  div.date-prev,
  div.date-next {
    a.btn {
      background: none;
      border: none;
      color: $black;
      font-size: 40px;
      line-height: 60px;
      padding: 0 20px;

      @include xxs {
        font-size: 30px;
      }
    }
  }

  div.date-prev a.btn {
    @include xxs {
      padding-left: 0;
    }
  }

  div.date-next a.btn {
    @include xxs {
      padding-right: 0;
    }
  }
}

// Sidebar calendar
.view-events.view-display-id-block_4 {
  .calendar-calendar {
    padding: 0;

    th {
      background: none;
      border: none !important;
      color: $black;
      font-size: 14px;
      font-weight: $weight-regular;
      padding-top: 8px;
    }

    td {
      border: none !important;
      color: $black;
      font-size: 14px;
      text-align: center;

      .month {
        padding: 4px 3px;

        @include xs {
          width: 30px;
        }
      }

      &.has-events {
        div.mini-day-on {
          @include xs {
            background: $blue;
            border-radius: 100px;
            color: $white;
            cursor: pointer;
          }
        }
      }

      &.today {
        background: none;
      }
    }
  }
}

aside .view-events.view-display-id-block_4 {
  .date-nav {
    background: $blue;
    text-align: center;

    div.date-prev,
    div.date-heading,
    div.date-next {
      margin: 0;

      a {
        color: $white;
        font-size: 20px;
        line-height: 40px;
      }
    }

    div.date-prev {
      float: left;
    }

    div.date-next {
      float: right;
    }

    div.date-heading {
      h1 {
        color: $white;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        line-height: 40px;
        text-transform: none;

        a {
          &:hover,
          &:focus {
            color: $white;
          }
        }
      }
    }
  }

  .calendar-calendar {
    td {
      &.has-events {
        div.mini-day-on {
          background: $blue;
          border-radius: 100px;
          color: $white;
          cursor: pointer;
        }
      }

      &.today {
        background: none;
      }
    }
  }
}

.calendar-calendar {
  .date-selected {
    border: 1px solid $black !important;
  }
}

.view-display-id-block_1 {
  .view-empty {
    display: none;

    &.no-events {
      display: block !important;
    }
  }
}

@include min-xs {
  .pre-content .calendar-calendar {
    margin-top: 30px;

    .month-view {
      thead,
      .single-day {
        display: none;
      }

      table {
        display: block;
        border: none;

        tbody {
          display: block;
          text-align: center;
        }

        tr {
          display: inline-block;

          td {
            cursor: pointer;
            border: none;
            display: inline-block;
            font-size: 20px;
            height: auto !important;
            max-height: 100px !important;
            padding: 0 8px !important;
            text-align: center;
            width: auto;

            div {
              padding: 0 !important;
            }

            &.sat,
            &.sun,
            &[headers='Saturday'],
            &[headers='Sunday'] {
              font-weight: $weight-bold;
            }

            &.has-events {
              background: $blue;
              border-radius: 100px;
              color: $white;
            }

            &.today {
              border: none !important;
            }

            &.empty {
              display: none !important;
            }

            .inner div.day {
              border: none;
              padding: 0;
              width: auto;
            }
          }
        }
      }
    }
  }
}
