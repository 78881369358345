// Mixins
// -----------------------------------------------------------------------------

// Media Queries
$xs-width: 500px;
$sm-width: 768px;
$md-width: 992px;
$lg-width: 1200px;

@mixin xxs { // < 500
  @media (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin xs { // < 768
  @media (max-width: #{$sm-width - 1px}) {
    @content;
  }
}

@mixin min-xs { // < 768
  @media (min-width: #{$sm-width - 1px}) {
    @content;
  }
}

@mixin sm { // < 992
  @media (max-width: #{$md-width - 1px}) {
    @content;
  }
}

@mixin md { // < 1200
  @media (max-width: #{$lg-width - 1px}) {
    @content;
  }
}

@mixin lg { // > 1200
  @media (min-width: #{$lg-width}) {
    @content;
  }
}

@mixin desktop { // > 992
  @media (min-width: #{$md-width}) {
    @content;
  }
}