// Styles for Nodes
// -----------------------------------------------------------------------------

.front .node-page {
  display: none;
}

.page-node-486,
.page-node-487 {
  #messages {
    display: none;
  }
}

// Teaser
.view-mode-calendar,
.node-teaser {
  border-bottom: 1px solid $gray-light;
  margin-bottom: 30px;
  padding-bottom: 30px;
  
  p {
    margin-bottom: 0;
  }
  
  p + ul,
  p + ol {
    margin-top: 10px;
    padding-bottom: 15px;
  }
  
  .left {
    padding-right: 15px;
  }
  
  .right {
    padding-left: 15px;
    padding-right: 20px;
  }
}

// Homepage
.front .node-teaser {
  .left {
    padding-right: 0;
  }
}

// Article
.node-article.view-mode-full {
  .field-name-field-image {
    margin: 0 15px 10px 0;
    width: 100%;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  .field-name-post-date {
    margin-bottom: 15px;
  }
}

.node-article {
  &.node-teaser {
    .field-name-field-image {
      @include xs {
        margin-bottom: 15px;
      }
    }
  }
}

// News teaser
.view-news.view-display-id-block_1 {
  .field-name-field-read-more-link .btn {
    display: none;
  }
}
