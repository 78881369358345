// Styles for sidebar.
// -----------------------------------------------------------------------------

aside {
  @include sm {
    margin-top: 30px;
  }
  
  .region-sidebar {
    margin-top: 80px;
    
    @include sm {
      margin-top: 0;
    }
    
    .block__content {
      p a {
        text-decoration: underline;
      }
      
      .field-name-title p a {
        text-decoration: none;
      }
    }
  }
  
  h1 {
    font-size: 52px;
    margin-bottom: -10px;
  }
  
  h2 {
    @extend h3;
    border-top: none;
    padding-top: 0;
    text-transform: none;
    
    &.block__title {
      text-align: left;
    }
  }
  
  hr {
    margin-top: -10px;
  }
  
  .block,
  .block.box {
    clear: both;
    width: 100% !important;
  }
  
  .block.box + .block.box {
    margin-top: -30px;
  }
  
  .block.cta {
    background: $blue-light;
    cursor: pointer;
    padding: 15px 30px 1px;
    text-align: center;
    
    h2 {
      color: $white;
      font-size: 52px;
      font-family: $font-family-serif;
      font-weight: $weight-regular;
      line-height: 1;
    }
    
    p {
      margin-bottom: 15px;
    }
  }
  
  .block p a img {
    @include sm {
      width: 100%;
      height: auto;
    }
  }
  
  // News 
  .view-stories,
  .view-news {
    .views-row-even {
      margin: 12px 0;
    }
    
    .field-name-body {
      font-size: 14px;
    }
    
    .field-name-node-link {
      margin-top: 5px;
    }
  }
}
