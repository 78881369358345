// Styles for search results.
// -----------------------------------------------------------------------------
.page-search {
  h1 {
    font-size: 42px;
  }
}

.search-results {
  margin: 0;
  padding: 0;
  
  li {
    margin: 20px 0 50px;
    
    .search-snippet-info {
      padding: 0;
      
      strong {
        font-weight: $weight-regular;
      }
    }
    
    .search-info {
      margin: -8px 0 8px;
    }
    
    p {
      margin: 0;
    }
  }
}
