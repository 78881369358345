// Styles for blocks.
// -----------------------------------------------------------------------------

#main .block {
  margin-bottom: 30px;
}

.front #main .block {
  clear: both;
}

.block.no-title {
  .block__title {
    visibility: hidden;
  }
}

.not-front #content .block.block-views {
  margin-bottom: 0px;
  overflow: hidden;

  .block-views {
    margin-bottom: 20px;
  }
}

// Boxes
.view-stats .views-limit-grouping-group {
  padding: 0;

  .views-row {
    width: 100%;
  }
}

.view-stats,
.block.box {
  color: $white;
  margin: 0;
  padding: 0;
  background: #ccc no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: table;
  font-size: 22px;
  height: 260px;
  text-align: center;

  @include sm {
    height: auto;

    &.image {
      height: 220px;
    }
  }

  @include xxs {
    display: block;
  }

  p {
    margin-bottom: 0;
  }

  .block__content {
    display: table-cell;
    vertical-align: middle;
    padding: 20px;

    strong,
    .number {
      font-family: $font-family-serif;
      font-size: 48px;
      font-weight: $weight-regular;
      line-height: 1;
    }
  }

  .group-left {
    height: 260px;
    overflow: hidden;

    @include xxs {
      float: none;
      height: auto;
      width: 100%;
    }

    .field-name-body {
      display: table;
      height: 100%;
      width: 100%;

      @include xxs {
        display: block;
      }

      .field-items {
        display: table-cell;
        vertical-align: middle;
        padding: 20px;

        @include xxs {
          display: block;
        }

        strong {
          font-family: $font-family-serif;
          font-size: 48px;
          font-weight: $weight-regular;
          line-height: 1;
        }
      }
    }
  }

  .group-right {
    background: #ccc no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 260px;

    @include xxs {
      display: none;
      float: none;
      width: 100%;
    }
  }

  .background-image,
  &.background-image {
    img {
      display: none;
    }
  }

  .volunteer,
  &.pink {
    background-color: $pink;
  }

  .food,
  &.yellow {
    background-color: $yellow;
  }

  .donation,
  &.purple {
    background-color: $purple;
  }

  .economic,
  &.teal {
    background-color: $teal;
  }
}

aside .view-stats .views-row {
  .group-right,
  .group-left {
    float: none;
    width: 100%;
  }
}

.front .block.box {
  margin: 0 !important;

  @include sm {
    &.image {
      display: none !important;
    }
  }
}

// Donate
.block.donate {
  margin-left: -15px;
  margin-right: 15px;

  h2 {
    background: $purple;
    border: $gray-light;
    border-bottom: none;
    color: $white;
    font-family: $font-family-serif;
    font-size: 45px;
    line-height: 1;
    margin: 0;
    padding: 15px 15px;
    text-transform: none;
  }

  .block__content {
    border: 1px solid $gray-light;
    padding: 15px 15px 0;
  }
}

.get-involved .block.donate h2 {
  background: $pink;
}

.hungry-for-change .block.donate h2 {
  background: $teal;
}

// List formatted
ul.list-formatted,
.block.list-formatted .block__content ol,
.block.list-formatted .block__content ul {
  margin: 0 0 15px 0;
  padding: 0;

  li {
    border-right: 1px solid $white;
    display: inline-block;
    line-height: 1;
    margin-right: 8px;
    padding-right: 15px;

    @include sm {
      //border: none;
      //margin: 0;
      //padding: 0;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border: none;
    }

    a {
      color: $white;
      line-height: 1;

      &.fa {
        font-size: 26px;
      }
    }
  }
}

header .block.list-formatted {
  .block__content ol,
  .block__content ul {
    li {
      @include sm {
        border: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}

// Dial 211
.block.dial {
  @include sm {
    border-top: 1px solid $blue;
    padding-top: 20px;

    h1 {
      margin-bottom: 10px;
    }
  }
}

// Social
.block.social {
  .block__content {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 10px 0 0;
        padding: 0;

        a {
          font-size: 32px;
        }
      }
    }
  }
}

// Partners
.block.partners {
  .block__content {
    img {
      margin-right: 30px;

      @include sm {
        margin-bottom: 20px;
      }
    }
  }
}

.block--host-an-event,
.block--volunteer-with-us,
.block--donate-money,
.block--donate-food,
.block--advocate-for-change,
.block--understand-the-issues {
  p {
    margin-bottom: 11px;
  }
}
