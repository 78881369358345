// Styles for hero.
// -----------------------------------------------------------------------------
.node-slide {
  background: no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px 0 0;
  height: 600px;
  position: relative;
  color: $white;
  
  @include sm {
    height: auto;
    padding: 30px 0;
  }
  
  h1 {
    border-top: 5px solid $white;
    padding-top: 25px;
    
    @include md {
      font-size: 60px;
      line-height: 60px;
    }
    
    @include sm {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      padding-top: 10px;
    }
  }
  
  h3 {
    color: $white;
    font-size: 115%;
  }
  
  .field-name-body {
    font-size: 125%;
    
    @include sm {
      font-size: 100%;
    }
  }
  
  .field-name-field-link {
    margin-top: 60px;
    
    @include sm {
      margin-top: 20px;
    }
  }
}

.flexslider {
  margin: 0;
  border: none;
  background: none;
  border-radius: 0;
}

.flexslider .flex-direction-nav {
  @include sm {
    display: none;
  }
}
 
.flexslider .flex-direction-nav a {
  display: block !important;
  opacity: 1 !important;
  left: 30px !important;
  height: 30px;
  width: 30px;
  
  &:before {
    color: $white;
    font-size: 22px;
  }
}

.flexslider .flex-direction-nav a.flex-next {
  left: auto !important;
  right: 30px !important;
}

