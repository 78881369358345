// Styles for Menus and Navs
// -----------------------------------------------------------------------------
ul.menu#main-menu > li > a {
  overflow: hidden;
  position: relative;
  
  .fa {
    bottom: -6px;
    color: $black;
    display: none;
    left: 50%;
    margin-left: -5px;
    position: absolute;
  }
}

ul.menu#main-menu > li.open > a .fa,
ul.menu#main-menu > li > a:hover .fa {
  display: block;
  
  @include sm {
    display: none !important;
  }
}

ul.menu#main-menu > li.menu-link-find-food- > a .fa {
  color: $yellow;
}

ul.menu#main-menu > li.menu-link-donate- > a .fa {
  color: $purple;
}

ul.menu#main-menu > li.menu-link-get-involved- > a .fa {
  color: $pink;
}

ul.menu#main-menu > li.menu-link-hungry-for-change- > a .fa {
  color: $teal;
}

ul.menu#main-menu > li.menu-link-about- > a .fa {
  color: $blue-light;
}

ul.menu {
  &#main-menu {
    @include sm {
      margin-left: 0;
      margin-right: 0;
    }
    
    li {
      position: static;
      
      @include sm {
        clear: both;
      }
      
      a {
        background-color: transparent !important;
        color: $white;
        font-size: 18px;
        padding: 18px 16px;
        
        @include sm {
          font-weight: $weight-bold;
          font-size: 20px;
          padding: 12px 0 5px 0;
        }
        
        &:focus,
        &:hover {
          background: none;
        }
        
        .fa-caret-down {
          display: none !important;
        }
      }
      
      &.menu-link-find-food- {
        @include sm {
          border-top: 2px solid $yellow;
        }
        
        .dropdown-menu {
          background-color: $yellow;
        }
      }
      
      &.menu-link-donate- {
        @include sm {
          border-top: 2px solid $purple;
        }
        
        .dropdown-menu {
          background-color: $purple;
        }
      }
      
      &.menu-link-get-involved- {
        @include sm {
          border-top: 2px solid $pink;
        }
        
        .dropdown-menu {
          background-color: $pink;
        }
      }
      
      &.menu-link-hungry-for-change- {
        @include sm {
          border-top: 2px solid $teal;
        }
        
        .dropdown-menu {
          background-color: $teal;
        }
      }
      
      &.menu-link-about- {
        @include sm {
          border-top: 2px solid $blue-light;
        }
        
        .dropdown-menu {
          background-color: $blue-light;
        }
      }
      
      .dropdown-menu {
        top: 94px;
        width: 100%;
        padding: 10px 0;
        margin: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        
        @include sm {
          background-color: $blue !important;
          position: static;
          display: block;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0 !important;
          margin-bottom: 15px;
        }
        
        &.two-col li {
          float: left;
          width: 200px;
          
          @include sm {
            float: none !important;
            width: auto !important;
          }
          
          &:nth-child(odd) {
            clear: left;
          }
          
          &:first-child {
            clear: none;
          }
        }
        
        > li {
          
          > a {
            padding: 0;
            line-height: 1.6;
            
            @include sm {
              font-size: 18px;
              font-weight: $weight-regular;
            }
          }
          
          &.active {
            > a {
              background: none;
            }
          }
        }
      }
    }
  }
}

.admin-menu ul.menu#main-menu li .dropdown-menu {
  top: 123px;
}

// CTA Menu
.cta-menu {
  margin-bottom: 60px;
  
  ul {
    @extend .row;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    
    li {
      @extend .col-xs-6, .col-md-3;
      
      @include sm {
        margin-bottom: 15px;
      }
      
      @include xxs {
        width: 100%;
      }
      
      a {
        font-size: 22px;
        line-height: 44px;
        width: 100%;
      }
    }
  }
}
