// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.inline {
  display: inline-block;
  margin-right: 15px;
}

ul.inline {
  list-style: none;
  margin: 0 -10px;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}

.clear-left {
  clear: left;
}

.not-front #main-wrapper {
  margin-top: 124px;
  flex: 1;

  @include sm {
    margin-top: 94px;
  }
}

.pre-content,
.hero {
  margin-top: 94px;

  @include sm {
    margin-top: 64px;
  }
}

.pre-content {
  margin-bottom: -50px;
}

#main {
  margin-top: 20px;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin-bottom: 30px;
}

blockquote {
  border: none;
  font-family: $font-family-serif;
  font-size: 42px;
  font-weight: $weight-regular;
  line-height: 1.1;
  margin: 0 0 20px;
  padding: 15px 0 15px 15px;
  text-align: right;

  address {
    color: $blue;
    font-size: 70%;
    font-style: italic;
  }
}

hr {
  border-color: $blue;
}

small {
  font-size: 82%;
}

.label {
  display: inline-block;
  font-size: 14px;
  font-weight: $weight-regular;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  width: 55px;
}

.no-padding-right {
  padding-right: 0;
}

ol li {
  counter-increment: list;
  list-style-type: none;
  position: relative;

  &:before {
    content: counter(list) ".";
    left: -37px;
    position: absolute;
    text-align: right;
    width: 26px;
  }
}

