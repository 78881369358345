// Typography
// -----------------------------------------------------------------------------
h1 {
  font-family: $font-family-serif;
  font-weight: $weight-medium;
  line-height: 70px;
  margin: 0 0 20px;
  
  @include xs {
    font-size: 50px;
    line-height: 45px;
  }
}

.find-food .region-content h1 {
  color: $yellow;
}

.donate .region-content h1 {
  color: $purple;
}

.get-involved .region-content h1 {
  color: $pink;
}

.hungry-for-change .region-content h1 {
  color: $teal;
}

.about .region-content h1 {
  color: $blue-light;
}

h2 {
  border-top: 1px solid $blue;
  color: $blue;
  font-weight: $weight-light;
  margin: 0 0 10px;
  padding-top: 15px;
  text-transform: uppercase;
}

h3 {
  color: $blue;
  font-weight: $weight-bold;
  margin: 0 0 10px;
  text-transform: uppercase;
}
