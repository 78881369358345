// Styles for buttons.
// -----------------------------------------------------------------------------
.btn-default,
.btn {
  background: $blue;
  border-radius: 0;
  border: 1px solid $gray-light;
  color: $white;
  font-size: 13px;
  line-height: 1.2;
  padding: 8px 40px 7px;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;

  @include md {
    max-width: 100%;
    white-space: normal;
  }

  &.full {
    border: none;
    width: 100%;
  }

  &.yellow {
    background-color: $yellow !important;
  }

  &.purple {
    background-color: $purple !important;
  }

  &.pink {
    background-color: $pink !important;
  }

  &.teal {
    background-color: $teal !important;
  }

  &:focus {
    box-shadow: none;
    color: $white;
  }

  &:hover {
    background-color: $blue;
    border-color: $gray-light;
    color: $white;
    opacity: 0.8;
  }
}
