// Styles for fields.
// -----------------------------------------------------------------------------
.field-name-body {
  img {
    //width: 40%;
    height: auto;
    margin: 0 15px 15px 0;

    &.full {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }

  a {
    text-decoration: underline;

    &:hover {

    }
  }

  p + ol,
  p + ul {
    margin-top: -20px;
  }

  blockquote address {
    margin-top: -15px;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 30px;
    overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.field-name-field-link a {
  @extend .btn;
}

.field-name-field-intro-text {
  font-size: 22px;
  margin-bottom: 30px;
}

.field-name-node-link {
  margin-top: 25px;
}

.field-name-field-date {
  font-size: 15px;
  font-family: $font-family-serif;
}

.field-name-field-address {
  margin-bottom: 10px;

  .postal-code {
    display: block;
  }
}

.field-name-field-read-more-link {
  margin-top: 25px;

  .btn {
    white-space: inherit;
  }
}

body.front {
  .node-event {
    .field-name-field-read-more-link {
      display: none;
    }
  }
}
