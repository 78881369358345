// Styles for footer.
// -----------------------------------------------------------------------------
#footer {
  background: $blue;
  color: $white;
  margin: 60px 0 0 0;
  padding: 25px 0;
  
  .label {
    width: auto;
    
    @include sm {
      width: 55px;
    }
  }
  
  .inline {
    &.buttons {
      margin-bottom: 30px;
      margin-top: 15px;
      
      li:first-child {
        border-right: 1px solid $white;
        margin-right: 3px;
        padding-right: 15px;
        
        @include sm {
          border: none;
          margin-bottom: 20px;
          padding-right: 0;
        }
      }
    }
    
    li {
      @include sm {
        display: block;
      }
    }
  }
  
  .partners .block__content {
    border-left: 1px solid rgba(255, 255, 255, 0.8);
    
    @include sm {
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.8);
      margin-top: 15px;
      padding-top: 15px;
    }
    
    li {
      display: inline !important;
      
      img {
        margin: 0 15px 15px;
      }
    }
    
    li:first-child {
      margin: 10px 0 30px;
      width: 100%;
      
      @include sm {
        margin: 0;
        width: auto;
      }
    }
  }
  
  .btn {
    background-color: $blue-light;
    border: none;
    font-size: 12px;
    line-height: 1;
    padding: 8px 10px 6px;
  }
  
  .small {
    font-size: 75%;
  }
  
  .smaller {
    font-size: 60%;
    margin-left: 20px;
  }

  .block {
    @include xs {
      margin-bottom: 30px;
      overflow: hidden;
    }

    &.small {
      display: inline-block;
      width: auto;
    }

    &.block--follow-us {
      .block__content p,
      .block__content {
        @include xs {
          display: inline-block;
        }
      }

      .block__title,
      .block__content ul {
        @include xs {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }

  .block__title {
    border-top: none;
    color: $white;
    font-size: 18px;
    font-weight: $weight-bold;
    padding-top: 0;
    text-transform: none;
  }

  a {
    color: $white;
  }

  p {
    margin-bottom: 15px;
    
    &:last-child {
      margin: 0;
    }
  }
}
