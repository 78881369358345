// Styles for Views
// -----------------------------------------------------------------------------

// Events
.view-events {
  // Homepage
  &.view-display-id-block_2 {
    .view-header {
      float: right;
      margin-top: -50px;

      @include sm {
        float: none;
        margin-top: 0;
      }
    }

    .view-content {
      clear: both;
    }

    .more-link {
      clear: both;
      position: relative;
      top: -20px;

      @include sm {
        top: -50px;
      }

      a {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
}

// News
.attachment .view-stories,
.attachment .view-news {
  margin-bottom: 50px;

  .field-name-field-image {
    margin-bottom: 15px;
  }
}

// Locations
.view-locations {
  .form-item-address-distance,
  .form-item-address-unit,
  .geofield-proximity-origin-from {
    display: none;
  }

  .views-exposed-form .views-exposed-widget {
    float: none;
  }

  #edit-address-wrapper {
    display: none;
  }

  .view-header {
    label, input[type="radio"] {
      cursor: pointer;
    }
  }
}

aside {
  .block {
    .view-stats {
      width: 100%;
    }
  }
}

// .amt-food-distributed{  
//   background-color: black;
// } 