// Styles for header.
// -----------------------------------------------------------------------------
.admin-menu header {
  top: 29px !important;

  ul.menu#main-menu li .dropdown-menu {
    top: 94px;
  }
}

header {
  background: $blue;
  color: $white;
  left: 0;
  padding: 12px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  // &.sticky {
    // @include desktop {
      // height: 64px;
      // padding-top: 0;
      // padding-bottom: 5px;
//
      // .block-search {
        // -webkit-transition: all .2s ease-in-out;
        // transition: all .2s ease-in-out;
      // }
//
      // #logo {
        // height: 120%;
        // margin-top: -10px;
        // -webkit-transition: all .1s ease-in-out;
        // transition: all .1s ease-in-out;
      // }
//
      // .navbar .header-blocks {
        // margin-top: 24px;
        // -webkit-transition: all .1s ease-in-out;
        // transition: all .1s ease-in-out;
      // }
//
      // ul.menu#main-menu li a {
        // padding-top: 6px;
        // padding-bottom: 12px;
        // -webkit-transition: all .1s ease-in-out;
        // transition: all .1s ease-in-out;
      // }
//
      // ul.menu#main-menu li .dropdown-menu {
        // top: 64px;
      // }
    // }
  // }

  .block-search {
    display: none;
    position: absolute;
    top: 0;
    right: 47px;

    @include sm {
      background: $blue;
      padding: 15px;
      position: fixed;
      right: 0;
      top: 64px;
      width: 100%;

      input.form-text,
      .form-inline .form-group {
        width: 100%;
      }
    }

    .block__content {
      @include sm {
        width: 695px;
        margin: 0 auto;
      }

      @include xs {
        width: 100%;
      }
    }

    form {
      position: relative;
    }

    input.form-text {
      padding-right: 35px;
    }

    input.form-submit {
      display: none;
    }

    button {
      background: none;
      border: none;
      color: $blue;
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }

  #logo {
    margin: -20px 0 0 0;
    height: 170%;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    @include sm {
      height: 200%;
    }
  }

  .navbar {
    border: none;
    border-radius: 0;
    min-height: 1px;
    margin-bottom: 0;
    position: static;

    .navbar-header {
      float: left;
      vertical-align: middle;
    }

    .navbar-brand {
      height: 70px;
      padding: 0;

      @include sm {
        height: 40px;
      }
    }

    .navbar-toggle {
      margin: 5px 0 0 0;
      border: none;
      background: none;

      .icon-bar {
        background-color: $white;
      }
    }

    a.fa-search {
      color: $white;
      font-size: 26px;
      line-height: 1;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      @include sm {
        float: right;
        font-size: 21px;
        margin-right: 5px;
        margin-top: 9px;
      }
    }

    .header-blocks {
      float: right;
      margin-top: 0;
      position: relative;

      a.donate {
        margin-bottom: 10px;

        @include sm {
          padding: 5px 15px;
          font-size: 10px;
          margin-bottom: 0;
        }
      }

      @include sm {
        float: left;
        //margin-top: 17px;
      }

      ul {
        margin: 0 !important;
      }
    }
  }
}

.navbar-collapse {
  box-shadow: none;
  float: left;
  margin-bottom: -12px;
  vertical-align: bottom;

  &::-webkit-scrollbar {
    display: none;
  }

  @include md {
    padding-left: 0;
    padding-right: 0;
  }

  @include sm {
    position: absolute;
    float: none;
    clear: both;
    top: 60px;
    left: 0;
    width: 50%;
    background: $blue;
    overflow: hidden;
    z-index: 10;
  }

  @include xxs {
    width: 100%;
  }

  ul {
    margin-top: 24px;
  }
}
