//Import fonts
@font-face {
    font-family: 'Larken';
    font-weight: 400;
    src: url('../fonts/Larken-Regular.otf');
}

@font-face {
    font-family: 'Larken';
    font-weight: 600; 
    src: url('../fonts/Larken-ExtraBold.otf');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    
}

//hero area
.hero-div{
    display: flex;
    background: #0076bc; 
    position: relative;

    .hero-report-image{
        text-align: center;
        width: 60%; 

        @media(max-width: 767px){ 
            width: 100%;
        } 
        
        @media (min-width: 2560px){ 
            width: 91%;
        }

        @media (min-width: 1920px){
            width: 80%;
        }  
 
        img{
            background: no-repeat center center;
            background-size: cover;
 
            @media(max-width: 1200px){
                height: 575px !important; 
            }

            @media(max-width: 768px){
                height: 500px !important;
            }

            @media(max-width: 767px){
                 height: 400px !important;
                
            }

            @media (min-width: 2560px){
                height: 683px !important;
                padding-top: 4%;
                padding-bottom: 4%;
                height: 628px !important;
            }

        }   
    }
    .report-year{ 
        width: 387px;
        position: absolute;
        top: 47%;
        left: 41%; 
 
        @media(max-width: 1200px){
            left: 40%; 
        }

        @media(max-width: 768px){
            left: 43%; 
            width: 301px;
        }

        @media(max-width: 767px){
            top: 86%;  
            width: 91%;
            left: 5%;
        }

        @media (min-width: 2560px){
            left: 42%; 
        } 

        @media (min-width: 1920px){
            left: 45%;  
        }

        a{
            color: white;
            text-decoration: none;
            text-transform: uppercase; 
            font-size: 19px;
            padding: 5px;
            border: 2px solid white;
            text-align: center;
            font-family: 'Roboto';
            font-weight: 600;
            padding-left: 0%;
            padding-right: 0%;
 
            @media(max-width: 768px){ 
                font-size: 15px;

            }
 
        }
    }
}


    
.famers-section{
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 970px;

}
 
.sample-speech{
    transform: translateY(-41%); 
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 25px;
    padding-bottom: 25px; 
    background: #0176BD;
    margin-bottom: 5% !important;

    @media(max-width: 768px){ 
        
        margin-bottom: -5% !important; 
        transform: translateY(-36%); 
    }

    @media(max-width: 767px){ 
        margin-left: 0%; 
        margin-right: 0%; 
        transform: none;
        margin-top: 0%;
    }
 
    .block__content{   
        h2{  
            color: white;
            text-transform: none; 
            font-family: larken;
            font-weight: 600;
            font-size: 35px;

            @media(max-width: 767px){
                font-size: 24px;

            }

        }
        #relief{
            margin-bottom: 30px;
            margin-top: -15px;
            font-family: larken;
            font-weight: 600;
            font-size: 35px;

            @media(max-width: 767px){
                font-size: 24px;

            }
        }

        p{
            font-size: 15px;
            font-family: 'Roboto';

            @media(max-width: 767px){
                font-size: 15px;

            }
        }
    }
    
}

.views-field.views-field-field-program-highlights-image{
        margin-left: auto;
        margin-right: auto; 
        padding-left: 15px;
        padding-right: 15px;
        max-width: 970px; 
   
} 

//circular border style

.circle{
    width: 200px; 
    height: 195px;
    border: 1px solid #0176BD;
    border-radius: 100px;
    position: relative; 
    margin-left: 30px;
    margin-bottom: 30px; 
    display: inline-block;
    align-items: center;
    justify-content: center;

    @media(max-width: 768px){
        margin-bottom: 13px;
    }

    @media(max-width: 767px){
        margin-left: 0px;
        transform: translateX(25%);

    }

}

//blue button
.annual-rep-blue-btn{
    padding: 15px;
    border: 1px solid;
    width: 62%;
    background: #0176BD;
    color: white; 
    text-decoration: none; 
    text-transform: uppercase;

    &:hover{
        cursor: pointer;
        color: white;
        text-decoration: none;
    }

    @media(max-width: 1200px){
        font-size: 15px;
    }

    @media(max-width: 768px){
        font-size: 9px;
    }

    @media(max-width: 767px){
        font-size: 12px;
    }
}

//purple button
.annual-rep-purple-btn{ 
    padding: 15px; 
    border: 1px solid;
    width: 62%;
    background: #463076;  
    color: white; 
    text-decoration: none; 
    text-transform: uppercase;

    &:hover{
        cursor: pointer;
        color: white;
        text-decoration: none;
    }

    @media(max-width: 1200px){ 
        font-size: 15px;
    }

    @media(max-width: 768px){
        font-size: 9px;
    }

    @media(max-width: 767px){
        font-size: 12px;
    }
}
.annual-report-statistic-wide {
    margin-top: -6%;

    @media(max-width: 767px){  
        margin-top: 13% !important;
    }
    @media(max-width: 1200px){ 
        margin-top: -13%;
    } 
    .views-field.views-field-nothing{
        background: #C3E2DF;
        margin-bottom: 10% !important; 
        padding-bottom: 1%;
  
    } 
}
 
//circular statistic section
.sec2{
    padding-top: 5%;
    padding-left: 5%;

    @media(max-width: 1200px){
        padding-right: 5%;
    }
}

.right-sec.sec-two{
    margin-top: 2% !important;
    padding-bottom: 11%;
}
.main-sec{
    display: flex;
    color: black !important;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 970px;

    @media(max-width: 767px){
        display: inline-block;

    }

    .celebration-speech{
        width: 40%;

        @media(max-width: 767px){
            width: 98%;
            margin-top: -14%;
        }

        h2{
            border-top: none; 
            text-transform: none; 
            width: 45%; 
            padding-bottom: 5%;
            border-bottom: 1px solid black;
            margin-bottom: 5%; 
            color: black; 
            font-family: larken;
            margin-top: 25%;
            font-weight: 600;
            font-size: 35px;

            @media(max-width: 1200px){
                width: 67%; 
                font-size: 35px;
            } 

            @media(max-width: 768px){
                width: 74%;
                font-size: 35px;
            }

            @media(max-width: 767px){
                font-size: 24px;
            }

        } 

        p{
            font-family: 'Roboto';
            width: 88% !important;
            font-size: 15px;

            @media(max-width: 1200px){
                width: 95% !important;
                font-size: 15px;
            }

            @media(max-width: 768px){
                width: 100% !important;
                font-size: 15px;
            }

            @media(max-width: 767px){
                width: 100% !important;
            }

            &:first-child{ 
                font-size: 15px;
            }

            &:last-child{ 
                @media(max-width: 767px){
                    margin-bottom: 23%;
                }
            }
        }
        
    }

    .food-speech{
        width: 46%; 
        font-family: 'Roboto';

        @media(max-width: 767px){
            width: 100% !important;
        }

        h2{
            border-top: none;   
            text-transform: none;   
         
            width: 64%; 
            padding-bottom: 5%;
            border-bottom: 1px solid black;
            margin-bottom: 5%; 
            color: black; 
            font-family: larken;
            font-weight: 600;
            font-size: 35px;

            @media(max-width: 1200px){
                font-size: 35px;
            }
            @media(max-width: 767px){
                font-size: 24px;
            }
        }  

        p{
            font-family: 'Roboto';
            width: 80% !important;
            font-size: 15px;

            @media(max-width: 1200px){
                width: 95% !important;
            }
            @media(max-width: 768px){
                font-size: 15px;
                width: 100% !important;
            }

            &:first-child{ 
                font-size: 15px;
            }

            &:last-child{ 
                @media(max-width: 767px){
                    margin-bottom: 23%;
                }
            }
        }
    }
    
    .right-sec{
        margin-top: 9%;
        
        //first circular row
        .row1-statistic{
            display: flex;

            @media(max-width: 767px){
                display: inline-block;
            }

            h3{
                text-align: center; 
                font-size: 25px;
                text-transform: none;
                color: black;
                margin-top: 25%;
                font-family: 'larken';

                strong{
                    font-weight: 600; 
                }
            } 

            p{ 
                text-align: center;
                padding: 0 10px 10px; 
                font-size: 24px;
                text-transform: none;
                font-size: 16px;
                text-transform: none;
                font-family: 'Roboto';
            }
            
        }

        //second circular row
        .row2-statistic{
            display: flex;

            @media(max-width: 767px){
                display: inline-block;
            }

            h3{
                text-align: center;
                font-size: 25px;
                text-transform: none;
                color: black;
                margin-top: 25%;
                font-family: larken;

                strong{
                    font-weight: 600; 
                }
            } 

            p{ 
                text-align: center;
                padding: 0 10px 10px; 
                font-size: 24px;
                text-transform: none;
                font-size: 16px;
                text-transform: none;
                font-family: 'Roboto'; 
            }

        }

        //third circular row
        .row3-statistic{
            display: flex;

            @media(max-width: 767px){ 
                display: inline-block;
            }

            h3{
                text-align: center;
                padding: 0 10px 10px; 
                font-size: 25px;
                text-transform: none;
                color: black;
                margin-top: 25%;
                font-family: larken;

                strong{ 
                    font-weight: 600; 
                }
            } 

            p{ 
                text-align: center;
                padding: 0 10px 10px; 
                font-size: 24px;
                text-transform: none;
                font-size: 16px;
                text-transform: none; 
                font-family: 'Roboto'; 
            }

        }
    }
}

.program-highlight-block {
    width: 42%;
    transform: translateX(159%);
    margin-top: -51%;
    color: white;
    background: #C64F68; 
    padding-right: 20px;
    padding-left: 20px; 
    padding-bottom: 35px;
    margin-bottom: 20% !important;

    @media(max-width: 1200px){
        width: 49%;
        transform: translateX(110%);
        margin-top: -49%;
        margin-bottom: 18% !important;
    }

    @media(max-width: 768px){ 
        margin-top: -59%;
        transform: translateX(87%);
        width: 54%;
        margin-bottom: 18% !important;
    }

    @media(max-width: 767px){
        transform: none;
        margin-top: 0%;
        width: 100%;
        
    }

    .block__content{

        p{
            font-family: 'Larken';
            font-size: 35px;
            font-weight: 600;
            padding-top: 40px; 
            white-space: normal;
            word-spacing: normal;
            padding-left: 8%;
            padding-right: 8%;
            line-height: 1.1;

            @media(max-width: 767px){
                font-size: 24px;         
            }
                 
        }

        h3{
            color: white;
            font-size: 24px;
            text-transform: none;  
            font-family: 'Roboto';
            font-size: 15px;
            font-weight: normal;
            padding-left: 8%;
            padding-right: 8%;
            line-height: 1.5;

            @media(max-width: 767px){
                font-size: 15px;         
            }
        }

        ul{
           
            li{ 
                list-style: none;
                padding-top: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid white;
                width: 87%;
                font-family: 'Roboto';

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 80px;

                    @media(max-width: 1200px){
                        padding-bottom: 40px;
                    }
                }            
            }
             
        }
    }
 } 
 .we-ask-for-support-block{
    margin-left: auto;
    margin-right: auto; 
    padding-left: 15px;
    padding-right: 15px;
    max-width: 970px; 
    margin-top: 5%;
    margin-bottom: 5% !important;

    @media(max-width: 767px){
        margin-top: -12%; 
        margin-bottom: 8% !important;
    }

     .block__content{

         h2{
            border-top: none;
            text-transform: none; 
            color: black; 
            font-family: Larken;
            font-size: 40px;
            font-weight: 600 !important;
            margin-left: 12%; 
            margin-right: 12%; 

            @media(max-width: 768px){
                font-size: 36px;
            }

            @media(max-width: 767px){
                font-size: 26px;
                margin-left: 8%; 
                margin-right: 8%; 
            }
         }
     }
 }
 .food-insecurity-statistic-block{
    margin-bottom: 5% !important;  
    background: #e3d7ed;

    .views-field.views-field-nothing{
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px; 
        padding-right: 15px;
        max-width: 970px; 
    }
 }
  
 .front-cover-div{  
     width: 45%;
     padding-top: 8%;
     padding-bottom: 8%; 
     background: #0176BD;
    
     @media(max-width: 767px){
        width: 100% !important;
        margin-top: 10%;
        margin-bottom: 19px !important;
    }

     .block__content{
         position: relative;

        h2{
           border-top: none; 
           color: white;
           text-transform: none;
           font-family: Larken;
        }
        p{
            color: white;
            position: absolute;
            left: 40%;
            top: 82px;
        }
     } 
 }
 .annual-report-highlight{ 
     width: 40%;
     transform: translateX(120%); 

     @media(max-width: 767px){
        width: 100%;
        transform: none;
    }

    @media(max-width: 768px){
        width: 47%;
        transform: translateX(103%);
    }

    @media(max-width: 767px){
        width: 100%;
        transform: none;
    }
    
     .front-text{
        position: relative;  
        .right-front-sec{ 
            position: absolute;
            top: -377px;

            @media(max-width: 767px){
                top: 0;
                position: static;
            }

            @media(max-width: 768px){ 
                top: -383px; 
            }

            .right-front-title{ 

                h2{
                    border-top: none;
                    text-transform: none;
                    color: black;
                    font-size: 35px;
                    font-weight: 600;
                    font-family: Larken;

                    @media(max-width: 1200px){
                        font-size: 35px;
                    }

                    @media(max-width: 768px){
                        font-size: 35px;
                    }
                    @media(max-width: 767px){
                        font-size: 21px !important;
                    }
                }
            }

            .right-front-lower-text{
                color: white;
                background: #C58818;
                padding: 26px;
                margin-left: -16%;
                margin-top: 10px;

                @media(max-width: 767px){
                    margin-left: 0%;
                    margin-top: 0px;
                    padding: 17px;
                }

                h3{
                    text-transform: none;
                    color: white;
                    font-family: 'Roboto';
                    font-size: 15px;
                    font-weight: 500;
                    padding-left: 27px;

                    @media(max-width: 768px){ 
                        font-size: 15px;
                    }

                    @media(max-width: 767px){ 
                        padding-left: 0px;
                    }
                }

                .report-cta{
                    margin-top: 5%;
                    padding-left: 27px;
                    text-transform: uppercase;
                    
                    @media(max-width: 767px){ 
                        padding-left: 0px;
                    }
                    a{
                        color: white;
                        font-size: 15px;
                        border: 2px solid white; 
                        text-decoration: none;
                        padding: 4px;
                        font-family: 'Roboto';
                        font-weight: 600; 
                        
                        @media(max-width: 768px){ 
                            font-size: 15px;
                        }
                        @media(max-width: 767px){ 
                            font-size: 13px;
                        }
                    }
                } 
            }
        }
     }
 }

 //previous annual reports block

 .previous-annual-rep{
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 970px; 

    @media(max-width: 767px){
        margin-top: -4%;
    }
    .block__title{  
            border-top: none;
            color: black;
            font-family: Larken;
            text-transform: none;
            margin-bottom: 26px;
            font-weight: 600;
            font-size: 33px;

            @media(max-width: 767px){
                font-size: 24px;
            }
        
    }
 }
 .view-previous-annual-reports{

    .view-content{
        display: flex;
        flex-wrap: wrap;

        .views-row{
            width: 31.3%; 
            margin-left: 5px;  
            margin-right: 5px;
            margin-bottom: 35px; 
            
            @media(max-width: 767px){
                width: 46.3%; 
            }

            .rep-img{
                margin-bottom: 10px;
            }
            .rep-year-link{

                a{
                    cursor: pointer;
                    text-decoration: none;
                    color: black;
                    font-weight: 500;

                    &:hover{
                        text-decoration: none;
                        color: black; 
                    }
                }
            }

            .audited-fin-link{

                a{
                    cursor: pointer;
                    text-decoration: none;
                    color: black;
                    font-weight: 500;

                    &:hover{
                        text-decoration: none; 
                        color: black;
                        
                    }
                }
            }
        }
    }
 }

.foodbank-visit.circle{

    h3{ 

        strong{
            padding: 11px;
            font-size: 23px; 
        } 
    } 
}

.foodbank-supported.circle{

    h3{

        strong{
            padding: 11px;
            font-size: 23px; 
        }
    }
}

.mag-div{
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 970px;  
}

.highlights_image{
    margin-top: -5%;  
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 970px; 

    @media(max-width: 767px){
        margin-top: -1%;
     }
}
.speech-box{
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%; 
}
.view-annual-report-statistic .main-sec {

    .celebration-speech{
        @media(max-width: 1200px){
            width: 47%;
        }

        @media(max-width: 767px){
            width: 100%;
        }
    }
    .right-sec{
        @media(max-width: 767px){
            transform: translateX(5%); 
        }
    }
}

.annual-reports .field-name-body img {
    width: 40%;
}
