// Styles for Forms
// -----------------------------------------------------------------------------
.page-node-done {
  .region-content {
    .links {
      display: none;
    }
  }
}

textarea,
.form-control {
  border-color: $gray-light;
  border-radius: 0;
  box-shadow: none;
}

.form-control {
  height: 30px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

// Story Node add
form.node-event-form,
form.node-story-form {
  .grippie,
  fieldset.filter-wrapper {
    display: none;
  }
}

// Register Event
form.node-event-form {
  h2 {
    margin: 20px 0;
  }

  .form-type-date-popup {
    .form-item {
      .help-block {
        display: none;
      }
    }
  }

  #edit-field-event-contact.field-type-addressfield {
    .panel-default {
      border: none;
      box-shadow: none;
    }

    .panel-body {
      padding: 0;
    }
  }

  .field-type-datetime {
    .field-multiple-drag,
    .tabledrag-toggle-weight {
      display: none;
    }

    &.field-name-field-date-multiple {
      thead {
        display: none;
      }

      .panel-default {
        border: none;
        box-shadow: none;
      }

      .panel-heading {
        background: none;
        border: none;
        padding: 0;
      }

      .panel-body {
        padding: 0;

        .form-item {
          margin: 0;
        }
      }

      .panel {
        background: none;
        margin: 0;
      }

      .date-padding {
        padding: 0;
      }
    }
  }

  #edit-field-location {
    margin: 20px 0;
  }

  .field-name-field-raise-food,
  .field-name-field-raise-money {
    label {
      display: block;
    }

    .field-prefix,
    .field-suffix,
    .form-text {
      display: inline-block;
    }

    .form-text {
      width: 200px;

      @include xxs {
        width: 100px;
      }
    }
  }
}

// Webform
.webform-client-form {
  @extend .row;

  .webform-component-markup {
    @extend .col-xs-12;
  }

  .form-actions {
    clear: both;
    margin-left: 15px;
  }
}

fieldset.webform-component--event-goals-fieldset {
  border: none;
  box-shadow: none;
  margin-bottom: 1em;

  legend {
    @extend label;

    color: $blue !important;
    background: white !important;
    border: none !important;
    padding: 0 !important;
    font-size: 16px;
  }

  .panel-body {
    padding: 0;
    margin: 0;

    .webform-component-textfield {
      padding: 5px 0;
      line-height: 27px;

      label {
        font-weight: normal;
        margin-right: 0;
      }

      input.form-text {
        width: 150px;
        display: inline;
        margin: 0 5px;
      }
    }

    .webform-component--event-goals-fieldset--food-raised,
    .webform-component--event-goals-fieldset--money-raised {
      padding: 5px 0;
      margin: 0;

      span, input {
        float: left;
      }

      input {
        width: 50px;
      }
    }
  }
}

.node-event-form {
  fieldset#edit-field-event-contact-und-0 {
    legend.panel-heading {
      display: none;
    }
  }
}

aside {
  .view-locations .view-filters {
    .form-type-select, input.form-text {
      width: 100%;
    }
  }
}
