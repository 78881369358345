// Styles for accordions.
// -----------------------------------------------------------------------------
.view-locations, .ui-accordion,
.view-faq .ui-accordion,
.view-campaigns .ui-accordion {
  .accordion {
    margin-bottom: 50px;
  }
  
  .views-row {
    border-bottom: 1px solid $gray-light;
    margin: 0;
    padding: 0;
    
    .node-teaser {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
      clear: both;
      overflow: hidden;
    }
    
    .views-field-title {
      cursor: pointer;
      font-weight: $weight-bold;
      margin: 0;
      padding: 15px 0;
      outline: none;
    }
    
    .field-name-field-link {
      margin-top: 15px;
    }
    
    .ui-accordion-content {
      padding-bottom: 8px;
      clear: both;
      overflow: hidden;
    }
    
    .ui-accordion-header-icon {
      float: right;
    }
  }
}

.view-campaigns .ui-accordion {
  .field-name-field-image {
    margin-bottom: 15px;
  }
}

.view-faq .ui-accordion .views-row .ui-accordion-content {
  margin-bottom: 0;
  padding-bottom: 0;
  
  p + ol,
  p + ul {
    margin-top: -15px;
  }
}

.view-locations .ui-accordion {
  .accordion {
    padding-left: 15px;
    margin-bottom: 30px;
  }
  
  .accordion > h3 {
    font-size: 20px;
    margin-left: -15px;
  }
  
  .node-teaser {
    h3 {
      display: none;
    }
    
    .group-header {
      margin-bottom: 15px;
    }
    
    .field-name-field-location-type {
      text-transform: uppercase;
    }
    
    .field-label {
      font-weight: $weight-regular;
      text-transform: uppercase;
    }
  }
}

.view-locations .ui-accordion .views-row .views-field-title {
  font-weight: $weight-regular;
}

// Board of Directors
.view-board-of-directors .ui-accordion {
  .accordion {
    margin-bottom: 50px;
  }
  
  .views-row {
    border-bottom: 1px solid $gray-light;
    margin: 0;
    padding: 0;
    
    .node-teaser {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
      clear: both;
      overflow: hidden;
      
      .left {
        display: inline-block;
        float: none;
        width: 32%;
        
        @include xs {
          margin-bottom: 15px;
          width: 100%;
        }
      }
      
      .right {
        display: inline-block;
        float: none;
        vertical-align: middle;
        
        h3 {
          font-size: 20px;
        }
      }
    }
    
    .views-field-rendered-entity {
      cursor: pointer;
      margin: 0;
      padding: 15px 0;
      outline: none;
      position: relative;
    }
    
    .ui-accordion-content {
      margin-bottom: 20px;
      padding-bottom: 8px;
      clear: both;
      overflow: hidden;
    }
    
    .ui-accordion-header-icon {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -16px;
    }
  }
}

// FAQ
.view-faq .ui-accordion .views-row .views-field-title {
  font-weight: $weight-regular;
}
