// Styles for maps (Donate/Find locations).
// -----------------------------------------------------------------------------
//.front .view-locations.view-display-id-block {
 // .view-content {
   // display: none;
 //// }
//}

.block.map {
  h2 {
    @extend h3;
    margin-bottom: 20px;
  }
}

.view-locations:not(.view-display-id-block_2) {
  

  .views-exposed-form{
    margin-bottom: 30px;
  }

  .radios label {
    color: $black;
    font-weight: $weight-regular;
  }

  .form-type-select {
    position: relative;

    i {
      position: absolute;
      top: 0;
      right: 0;
      color: $white;
      background: $yellow;
      line-height: 30px;
      width: 35px;
      text-align: center;
      pointer-events: none;
    }
  }

  .view-filters {
    input.form-text,
    .form-type-select {
      width: 300px;

      @include xs {
        width: 100%;
      }
    }
  }

  .form-submit {
    margin-top: 0 !important;
  }

  // Active pin information
  .view-display-id-attachment_1 {
    background: $blue;
    color:  $white;

    

    .node-teaser {
      border: none;
      margin: 0;
      padding: 20px 10px;
    }

    .group-header {
      margin-bottom: 15px;
    }

    h3 {
      color:  $blue;
      font-size: 18px;
      margin-bottom: 0;
    }

    .field-name-field-location-type {
      text-transform: uppercase;
    }

    .field-label {
      font-weight: $weight-regular;
      text-transform: uppercase;
    }

    a {
      color:  $blue
    }
  }

  //active pin styling on map
  .view-content {
    background: $blue;
    .node-location {
      .group-header {
        color: $blue;
        h3, a, span {
          color: $blue;
        }
      }
      .left {
        a, span {
          color: $blue;
        }
      } 
      .right, .group-footer {
        // background: $blue;
         color: $blue;
       }
    }

  }

  //footer search list styling 
  .view-footer {
    background-color: $blue;
    .view-content {
      .views-row {
        padding: 15px;
        margin-top: 0;
        .node-location {
          .group-header {
            color: $white;
            h3, a, span {
              color: $white;
            }
          }
          .left {
            a, span {
              color: $white;
            }
          } 
          .right, .group-footer {
            // background: $blue;
             color: $white;
           }
        }
        
      }
    }
    
  }
}
/*
.view-locations.view-display-id-block_1,
.view-locations.view-display-id-block_3 {
  margin-bottom: 30px;

  // Active pin information
  .view-content {
    background: $blue;
    color:  $white;

    .views-row {
      //display: none;
      @include sm {
        .left.col-xs-12 {
          margin-bottom: 10px;
        }
      }
    }

    .node-teaser {
      border: none;
      margin: 0;
      padding: 20px 10px;
    }

    .group-header {
      margin-bottom: 15px;
    }

    h3 {
      color:  $blue;
      font-size: 18px;
      margin-bottom: 0;
    }

    .field-name-field-location-type {
      text-transform: uppercase;
    }

    .field-label {
      font-weight: $weight-regular;
      text-transform: uppercase;
    }

    a {
      color:  $blue;
    }
  }
}
*/
.view-display-id-block_5,
.view-display-id-block_3 {
  .view-footer {
    .views-exposed-form {
      display: none !important;
        }
  }

}
  
  